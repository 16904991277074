<template lang="pug">
.ds-color-input-wrapper.design-system
  PaneLayout(:layout="layout" gap="8px")
    template(#left)
      .d-flex
        .ds-color-input-label(v-if="label") {{ label }}
        DeviceSelector(v-if="deviceSelector && editMobile" :hasViewText="layout === 'row'")
    template(#right)
      .ds-color-input.cursor-pointer(:class="classes" @click="$emit('click', $event)")
        img.ds-color-input-image(
          v-if="shouldShowImage && !isSvg"
          :src="backgroundSrc"
          :style="coloringStyle"
          @error="createImageError"
        )
        inline-svg.ds-color-input-image(
          v-else-if="shouldShowImage && isSvg"
          :src="backgroundSrc"
          @loaded="onSvgLoaded"
          @error="createSVGError($event, backgroundSrc)"
        )
        .ds-color-input-swatch(v-else :style="coloringStyle")
</template>

<script>
  import { nanoid } from 'nanoid';
  import createLogMixin from '@/mixins/createClientLog';
  import PaneLayout from '../PaneLayout.vue';
  import { COLORING_TYPES } from '../../../utils/color-components/helper';

  export default {
    name: 'ColorInput',
    components: {
      PaneLayout,
      DeviceSelector: () => import('@/editor/components/sidebar/components/DeviceSelector.vue'),
    },
    mixins: [createLogMixin],
    props: {
      size: {
        type: String,
        default: 'sm',
        options: ['sm', 'lg'],
        validator: (value) => {
          return ['sm', 'lg'].includes(value);
        },
      },
      label: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
      deviceSelector: {
        type: Boolean,
        default: false,
      },
      layout: {
        type: String,
        default: 'row',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
      isSvg: {
        type: Boolean,
        default: false,
      },
      coloringStyle: {
        type: String,
      },
      backgroundSrc: {
        type: String,
      },
      coloringType: {
        type: String,
      },
      colorValue: {
        type: String,
      },
    },
    data: () => ({}),
    computed: {
      shouldShowImage() {
        return (!this.coloringType && this.backgroundSrc) || this.coloringType === this.imageType;
      },
      imageType() {
        return COLORING_TYPES.IMAGE;
      },
      classes() {
        return {
          'ds-color-input-small': this.size === 'sm',
          'ds-color-input-large': this.size === 'lg',
        };
      },
    },
    methods: {
      onSvgLoaded(svg) {
        const pattern = /url\(#([a-zA-Z0-9_]+)\)"/gm;
        let match = pattern.exec(svg.innerHTML);
        while (match) {
          svg.innerHTML = svg.innerHTML.replace(new RegExp(match[1], 'g'), `${nanoid(8)}`);
          match = pattern.exec(svg.innerHTML);
        }
      },
    },
  };
</script>

<style lang="sass">
  @import '../../../sass/variables/_colors.sass'

  .ds-color-input-wrapper
    gap: 1rem
    .ds-color-input
      width: 100%
      border: 1px solid $om-light-grey
      border-radius: 4px
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC')
      position: relative
      &-label
        font-size: 0.75rem
        font-weight: 400
        color: $om-dark-grey-3
      &-small
        height: 2rem
        max-width: 100%
      &-large
        height: 5rem
      &-swatch
        height: 100%
        width: 100%
        border-radius: 4px
      &-image
        pointer-events: none
        position: absolute
        -o-object-fit: contain
        object-fit: contain
        height: 100%
        width: auto
        top: 0
        left: 0
        right: 0
        margin: auto
</style>
